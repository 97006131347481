import React from 'react';

type ErrorFormLabelProps = {
  error: unknown;
  message: string;
};

export default function ErrorFormLabel({ error, message }: ErrorFormLabelProps) {
  return (
    <>
      {error && <span className="text-danger">{message}</span>}
    </>
  );
}
